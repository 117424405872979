// Layers
// ======

$z-ui: 10;
$z-header: 100;
$z-overlay: 1000;

// Spacing & Corners
// =================

// Padding & Margins
// -----------------

$space-xl: 1.25rem; // 20px
$space-lg: 1rem; // 16px
$space-md: 0.75rem; // 12px
$space-sm: 0.5rem; // 8px
$space-xs: 0.25rem; // 4px

// Rounded Corner
// --------------

$corner-xl: 2.75rem; // 44px
$corner-lg: 2.5rem; // 40px
$corner-md: 1.5rem; // 24px
$corner-sm: 0.75rem; // 12px
$corner-xs: 0.5rem; // 8px

// Colors
// ======

// Brand: Red
// ----------
$red: #d40000;
$red-dark: #970000;
$red-neon: #ea0000; // used for glow

// Red Glow
// --------

// Text

$red-glow-text: 0 0 0.5rem rgba($red-neon, 0.6), 0 0 0.5rem rgba($red-neon, 0.8),
  0 0 0.5rem rgba($red-neon, 0.8);
$red-glow-text-lg: 0 0 0.25rem rgba($red-neon, 0.8),
  0 0 0.5rem rgba($red-neon, 0.8), 0 0 0.5rem rgba($red-neon, 0.8),
  0 0 0.25rem rgba($red-neon, 0.8), 0 0 0.5rem rgba($red-neon, 0.8),
  0 0 0.5rem rgba($red-neon, 0.8);
$red-glow-text-xl: 0 0 0.25rem rgba($red-neon, 0.5),
  0 0 0.5rem rgba($red-neon, 0.5), 0 0 1rem rgba($red-neon, 0.5),
  0 0 0.5rem rgba($red-neon, 0.5);

// Box

$red-glow-box-sm: 0 0 0.5rem 0.125rem rgba($red-neon, 0.6);
$red-glow-box-lg: 0 0 0.75rem 0.5rem rgba($red-neon, 0.6);
$red-glow-box-inset: inset 0 0 0.5rem $red-neon;

// Icon

$red-glow-icon-sm: drop-shadow(0 0 0.25rem rgba($red-neon, 0.6))
  drop-shadow(0 0 0.25rem rgba($red-neon, 0.8));
$red-glow-icon: drop-shadow(0 0 0.5rem rgba($red-neon, 0.6))
  drop-shadow(0 0 0.5rem rgba($red-neon, 0.8));
$red-glow-icon-lg: drop-shadow(0 0 0.5rem rgba($red-neon, 0.6))
  drop-shadow(0 0 0.5rem rgba($red-neon, 0.8))
  drop-shadow(0 0 0.25rem rgba($red-neon, 0.8));

// Brand: Neutrals
// ---------------
$neutral-lightest: #fff;
$neutral-lighter: #eee;
$neutral-light: #ccc;
$neutral-midtone: #777;
$neutral-dark: #333;
$neutral-darker: #202020;
$neutral-darkest: #111;

// Text & Fonts
// ============
$text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

// Font Sizes
// ----------
$font-size-xl: 1.5rem; // 24px
$font-size-lg: 1.25rem; // 20px
$font-size-md: 1rem; // 16px
$font-size-sm: 0.875rem; // 14px
$font-size-xs: 0.75rem; // 12px
$font-size-xxs: 0.65rem; // 10px

// Letter Spacing
// --------------
$letter-spacing-sm: 0.05em;
$letter-spacing-md: 0.1em;

// Font Family
// -----------
$font-nunito: 'nunito';
$font-josefinsans: 'josefinsans';

// Media Queries
// -------------------
$screen-xs-min: 480px;
