@import '../mixins';

// Button Root Styles
// ------------------

.button {
  padding: $space-lg $space-xl $space-md; // uneven to combat font's wonky line height
  min-height: 2.75rem;
  min-width: 4.375rem;
  background: $neutral-darkest;
  border: 0.15rem solid $neutral-lightest;
  border-radius: $corner-md;
  font-family: $font-josefinsans;
  font-size: $font-size-sm;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-sm;
  color: $neutral-lightest;
  cursor: pointer;

  // States
  // ------

  &:active {
    box-shadow: $red-glow-box-lg;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgba($red-neon, 0.6);
    outline: none;
  }

  &:disabled {
    cursor: default;
  }

  // Modifier: with icon
  // ---------
  &.button--with-icon {
    padding: $space-sm $space-lg $space-xs;
  }
}

// Button Icon
// ===========

.button__icon {
  flex: 0 0 auto;
  vertical-align: middle;
  margin: 0 $space-xs $space-xs 0;

  // Modifier: Icon Spacing for Tertiary Button
  // ------------------------------------------
  .button--tertiary & {
    margin: 0 0 $space-xs;
  }
}

// Button Variants
// ===============

// Primary Button
// --------------

.button--primary {
  border: 0;
  background: $red;
  box-shadow: $red-glow-box-sm;

  &:active {
    background: $red-neon;
  }

  &:disabled {
    background: $neutral-dark;
    box-shadow: none;
    color: $neutral-light;
  }
}

// Secondary Button
// ----------------

.button--secondary {
  border-color: $red-neon;
  box-shadow: $red-glow-box-sm;

  &:active {
    border-color: $red-neon;
  }

  &:disabled {
    border-color: $neutral-dark;
    box-shadow: none;
    color: $neutral-light;
  }
}

// Tertiary Button
// ---------------

.button--tertiary {
  padding: $space-md $space-lg $space-sm;
  font-size: $font-size-xs;
  min-height: 2.25rem;

  &:disabled {
    border-color: $neutral-dark;
    box-shadow: none;
    color: $neutral-light;
  }

  &.button--with-icon {
    padding: $space-xs $space-sm 0;
  }
}

// Account Item Button
// -------------------

.button--account {
  display: flex;
  align-items: center;
  padding: $space-lg;
  font-size: $font-size-sm;
  min-height: 2.5rem;
  border: 0;
  width: 100%;
  text-align: left;
  line-height: 1.35;

  & + & {
    margin-top: $space-xs; // spacing between like items
  }

  &:disabled {
    box-shadow: none;
    color: $neutral-light;
  }

  &.button--with-icon {
    padding: $space-sm $space-md $space-xs;
  }

  .button__icon {
    margin-right: $space-md;
  }
}

.button--stripped {
  min-height: auto;
  min-width: auto;
  padding: 0;
  border: 0;
  border-radius: 0;
  background: none;
}
