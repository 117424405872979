@import '../mixins';

.icon--xs {
  width: 1rem; // 16px
  height: 1rem; // 16px
}

.icon--sm {
  width: 1.25rem; // 20px
  height: 1.25rem; // 20px
}

.icon--md {
  width: 1.5rem; // 24px
  height: 1.5rem; // 24px
}

.icon--lg {
  width: 2rem; // 32px
  height: 2rem; // 32px
}

.icon--xl {
  width: 2.25rem; // 36px
  height: 2.25rem; // 36px
}

.icon--glow {
  display: inline-block;
  filter: $red-glow-icon;
}

.notification-icon {
  display: none;
}

.icon--notification .notification-icon {
  display: block;
  width: 5px;
  height: 5px;
  background-color: $red-neon;
}

.icon-favourite.icon--xs {
  path {
    fill: $red-neon;
  }
}

.icon-circle {
  filter: $red-glow-icon;
}

.icon-block.icon--xs {
  path {
    fill: $red-neon;
  }
}
