@import '../mixins';

.select-input {
  position: relative;
  background: $neutral-darker;
  border-radius: $corner-xs;
}

// Label Button
// ============
// Button that triggers the dropdown to open

.select-input__label-button {
  z-index: 11;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: $space-sm $space-sm $space-sm $space-md;
  background: $neutral-dark;
  border: 0.125rem solid $neutral-dark;
  border-radius: $corner-xs;
  outline: 0;
  text-align: left;
  font-size: $font-size-md;
  font-family: $font-nunito;
  color: $neutral-light;
  cursor: pointer;

  // States

  &:focus,
  &:active {
    border-color: $neutral-lightest;
  }

  // When Open

  .select-input--open & {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

// Option List
// ===========

.select-input__option-list {
  z-index: 12;
  position: absolute;
  top: 2.75rem;
  left: 0;
  right: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  min-height: 2rem;
  max-height: 10.25rem;
  margin: 0;
  padding: $space-xs 0;
  outline: 0;
  background: $neutral-darker;
  border-radius: 0 0 $corner-xs $corner-xs;
  box-shadow: 0 $space-xs $space-sm rgba($neutral-darkest, 0.8);
  list-style: none;

  .select-input--closed & {
    display: none;
  }
}

.select-input__option {
  padding: $space-xs;
}

.select-input__option-button {
  width: 100%;
  padding: $space-sm $space-md;
  outline: 0;
  border: 0;
  border-radius: $corner-md;
  background: transparent;
  text-align: left;
  font-size: $font-size-md;
  font-family: $font-nunito;
  color: $neutral-light;
  cursor: pointer;

  &.select-input--highlighted {
    background: $neutral-dark;
  }
}
