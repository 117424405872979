@import '../mixins';

.upload-photo {
  display: flex;
  flex-direction: column;
}

.upload-photo__image {
  width: 100%;
}

.upload-photo__heading {
  display: block;
  margin-bottom: $space-md;
}

.upload-photo__intro {
  margin-bottom: $space-xl;
}

.upload-photo__feedback {
  margin-top: $space-md;
}

.upload-photo__input-button {
  margin-top: $space-lg;
}

.upload-photo__input {
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  height: 2.5rem;
  width: 100%;
}

.upload-photo__buttons {
  display: flex;
  flex-direction: column;
  margin-top: $space-sm;
}

.upload-photo .button__label {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
