@import '../mixins';

.header-app {
  display: flex;
  justify-content: space-between;
  padding: $space-sm $space-sm $space-sm $space-lg;
  background: $neutral-darkest;
}

.header-app__logo {
  min-width: auto;
}

.header-app__nav {
  display: flex;
  flex: 0 0 auto;
}

.header-app__nav-item {
  margin-left: $space-sm;
}
