@import '../mixins';

.sign-in {
  display: flex;
  flex-direction: column;
  padding: $space-xl * 2 $space-lg;
  min-height: auto;
}

.sign-in__description {
  margin-top: $space-md;
  color: $neutral-light;
}

.sign-in__form {
  display: flex;
  flex-direction: column;
  margin: $space-xl 0;
}

.sign-in__button {
  align-self: flex-end;
  margin: $space-md 0;
}

.signedIn {
  height: auto;
  min-height: auto;
}
