@import '../mixins';

.edit-photos__heading {
  display: block;
  margin-bottom: $space-md;
}

.edit-photos__intro {
  margin-bottom: $space-xl;
}

.edit-photos__button {
  display: flex;
  justify-content: flex-end;
}

.edit-photos__add-button {
  margin-bottom: $space-xl;
}

.edit-photos__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: $space-md;
  row-gap: $space-md;
  padding: 0;
  list-style: none;
}

.edit-photos__list-item {
  position: relative;
}

.edit-photos__photo {
  display: block;
  width: 100%;
}

.edit-photos__photo-delete {
  position: absolute;
  top: 0;
  right: 0;
  min-height: 1.75rem;
  min-width: 1.75rem;
}
