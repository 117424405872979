@import '../mixins';

.exhibition-heading {
  display: flex;
}

.exhibition-heading__icon {
  margin-right: $space-lg;
  overflow: visible;
  height: 5rem;
  width: 5rem;

  path {
    fill: $red;
    filter: $red-glow-icon-sm;
  }
}

.exhibition-heading__title {
  margin-top: $space-lg;
  font-size: 4rem;
  font-weight: 400;
  font-family: $font-josefinsans;
  text-transform: uppercase;

  strong {
    color: $red;
    text-shadow: $red-glow-text-xl;
  }
}
