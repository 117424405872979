@import '../mixins';

.listing {
  display: flex;
  background: none;
  border: none;
  color: #fff;
  font-family: $font-nunito;
  width: 100%;
}

.listing-container {
  position: relative;
  width: 9.25rem;
  min-width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

.listing__status {
  position: absolute;
  bottom: $space-sm;
  left: $space-sm;
  max-width: 80%;
  box-shadow: 0 0 2.75rem 0.5rem rgba(0, 0, 0, 0.9);
}

.listing__image {
  width: 100%;
  object-fit: cover;
}

.listing--active .listing-container {
  border: solid 0.125rem $red;
  box-shadow: $red-glow-box-inset;
}
