@import '../mixins';

.footer {
  display: flex;
  justify-content: space-between;
  padding: $space-md;
  background: $neutral-darker;
  font-size: $font-size-sm;
  color: $neutral-light;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
}

.footer__showcase-link {
  font-size: $font-size-xs;
  text-transform: none;
  margin-right: $space-sm;
}

.footer__language-link {
  flex-grow: 1;
  text-align: left;
  margin-left: $space-md;
}
