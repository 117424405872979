@import '../mixins';

.edit-list {
  list-style: none;
  margin-top: $space-lg;
}

.edit-list__item {
  display: flex;
  align-items: center;
  padding: $space-md 0;
  border-bottom: 0.1rem solid $neutral-dark;

  &:first-child {
    border-top: 0.1rem solid $neutral-dark;
  }
}

.edit-list__name {
  flex: 1 1 auto;
  display: block;
  margin: 0 $space-md;
  font-weight: bold;
}

.edit-list__button {
  flex: 0 0 auto;
}
