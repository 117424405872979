@import '../mixins';

.profile-icon {
  position: relative;
  outline: 0;
  border: 0;
  background: transparent;
}

.profile-icon__image-container {
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  border-radius: $corner-md;
}

.profile-icon__image {
  width: 100%;
}

.profile-icon__icon {
  position: absolute;
  bottom: -0.25rem;
  right: -0.25rem;
  filter: none;

  circle {
    stroke: $neutral-darker;
    stroke-width: 0.2rem;
  }

  .profile-icon--online & circle {
    fill: $red;
  }

  .profile-icon--inactive & circle {
    fill: $neutral-lightest;
  }

  .profile-icon--offline & circle {
    fill: $neutral-midtone;
  }
}
