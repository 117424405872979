@import '../mixins';

.notification {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  width: 100%;
  padding: $space-md 0 0 $space-lg;
  background: $neutral-darker;
  border: 0;
  font-family: $font-nunito;
  font-size: $font-size-md;
  color: $neutral-lightest;
  text-align: left;

  &:focus,
  &:active {
    border: 0;
    outline: 0;
    background: $neutral-darkest;
  }
}

// User Pic
// --------

.notification__user-icon {
  flex: 0 0 1.75rem;
  width: 1.75rem;
  height: 1.75rem;
  overflow: hidden;
  margin-right: $space-md;
  border-radius: 100%;

  > img {
    width: 100%;
    height: 100%;
  }
}

// Notification Content
// --------------------

.notification__content {
  flex: 1 1 auto;
  min-width: 0;
  padding: 0 $space-lg $space-md 0;
  border-bottom: 0.1rem solid $neutral-dark;
}

// Notification User Details
// -------------------------
// User name, favourite icon, action label

.notification__user {
  display: flex;
  align-items: center;
}

.notification__user-name {
  font-weight: 800;
  margin-right: $space-xs;
}

.notification__user-favourite {
  margin-left: $space-xs;
  margin-bottom: 0.1rem;
  vertical-align: middle;
}

.notification__user-action {
  margin-left: $space-xs;

  // Temp hiding
  .notificaton--inbox & {
    display: none;
  }
}

// Notification Detail
// -------------------
// Message, date, unseen indicator

.notification__detail {
  display: flex;
  justify-content: space-between;
  align-content: baseline;
  color: $neutral-midtone;
}

.notification__detail-message {
  flex: 1 1 auto;
  min-width: 0;
  margin-right: $space-md;
  font-size: $font-size-sm;

  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // Temp hiding
  .notificaton--update & {
    display: none;
  }
}

.notification__detail-meta {
  flex: 0 1 auto;
  display: flex;
  align-content: center;
  font-size: $font-size-xs;
  white-space: nowrap;
  color: $neutral-light;
}

// Unseen indicator placeholder
.notification__detail-indicator {
  margin-right: $space-xs;

  // Temp hiding
  .notification--seen & {
    display: none;
  }

  circle {
    fill: $red-neon;
  }
}
