@import '../mixins';

.logo--sm {
  width: auto;
  height: 1rem;
}

.logo--md {
  width: auto;
  height: 2rem;
}

.logo--lg {
  width: auto;
  height: 3rem;
}

.logo--xl {
  width: auto;
  height: 4.5rem;
}

.logo--glow {
  filter: $red-glow-icon-sm;
}
