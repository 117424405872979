@import '../mixins';

.chat-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $space-sm 0;
}

.chat-alert__title-strong {
  padding: 0 $space-xs/2;
  color: $neutral-lightest;
  font-weight: 800;
  text-decoration: none;
  text-shadow: $red-glow-text;
  text-transform: uppercase;
}

.chat-alert__icon {
  margin-right: $space-sm;
}
