@import '../mixins';

@keyframes service1 {
  0% {
    fill: $neutral-lightest;
  }
  10% {
    fill: $neutral-midtone;
  }
  70% {
    fill: $neutral-midtone;
  }
  80% {
    fill: $neutral-lightest;
  }
}

@keyframes service2 {
  0% {
    fill: $neutral-lightest;
  }
  20% {
    fill: $neutral-midtone;
  }
  50% {
    fill: $neutral-midtone;
  }
  60% {
    fill: $neutral-lightest;
  }
}

.fauxne__header {
  display: none;

  @media (min-width: $screen-xs-min) {
    z-index: $z-header;
    position: sticky;
    top: 0;
    background: $neutral-darkest;
    font-size: $font-size-xs;
    font-weight: 700;
    padding: 0 $space-md;
    min-height: 2rem;
    display: block;
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    display: none;
  }
}

.fauxne__notch {
  position: absolute;
  width: 10rem;
  left: calc(50% - 5rem);
  top: -0.031rem;
}

.fauxne__service {
  .bar_3 {
    animation: service2 4s ease-in-out 2s reverse infinite;
  }
  .bar_4 {
    animation: service1 4s ease-in-out 2s reverse infinite;
  }
}

.fauxne__time {
  padding-top: $space-sm;
  float: left;
}

.fauxne__network {
  padding-top: $space-sm;
  float: right;
}
