@import '../mixins';

.profile-description {
  padding: $space-xl $space-lg;
  background: $neutral-darker;
}

.profile-description__item {
  display: flex;

  // Spacing between items
  // Only siblings receive padding
  & + & {
    margin-top: $space-md;
  }
}

.profile-description__label {
  flex: 0 0 auto;
  width: 5rem; // to accomadate the french label
  margin-right: $space-md;
  font-size: $font-size-md;
  font-weight: 800;
}

.profile-description__content {
  color: $neutral-light;
  overflow: hidden;
  word-wrap: break-word;
}
