@import '../mixins';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  position: relative;
  background-color: $neutral-darkest;
  font-size: 16px;
  font-family: $font-nunito;
  color: $neutral-lighter;

  @media (min-width: $screen-xs-min) {
    min-height: 100vh;
    display: grid;
    place-items: center;
  }
}

ul,
ol {
  list-style-position: inside;
}

.list {
  margin-bottom: $space-lg;
  padding: 0 0 0 $space-md * 2;
  list-style-position: outside;
  color: $neutral-light;

  &:not(:first-child) {
    margin-top: $space-sm;
  }

  li {
    padding-left: $space-xs;

    &:not(:first-child) {
      margin-top: $space-sm;
    }
  }
}

.list--ol {
  list-style-type: decimal;

  ol {
    margin: 0;
    list-style-type: decimal;
  }
}

.list--ul {
  list-style-type: disc;

  ul {
    margin: 0;
    list-style-type: circle;
  }
}

.paragraph {
  margin-bottom: $space-lg;
  color: $neutral-light;
}

.header--sticky {
  z-index: $z-header;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;

  @media (min-width: $screen-xs-min) {
    top: 2rem;
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    top: 0;
  }
}

main {
  height: auto;
  position: relative;

  @media (min-width: $screen-xs-min) {
    height: 100%;
  }
}

.page {
  padding: $space-xl * 2 $space-lg $space-xl * 3;

  @media (min-width: $screen-xs-min) {
    padding-bottom: $space-xl * 2;
  }
}

.intro {
  font-size: $font-size-md;
  color: $neutral-light;
}

.heading {
  font-family: $font-josefinsans;
  font-weight: 400;
  text-transform: uppercase;
  font-size: $font-size-xl;
  line-height: 1.2;
}

.subheading {
  font-family: $font-josefinsans;
  font-weight: 600;
  text-transform: uppercase;
  font-size: $font-size-lg;
  line-height: 1.3;
}

.item-heading {
  font-size: $font-size-lg;
  font-weight: 600;
}

.label {
  font-size: $font-size-xs;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.account-menu {
  height: 100%;
  padding: 1rem;
  background-color: $neutral-darker;
}

.account-menu__header {
  display: flex;
  align-items: center;
  margin-bottom: $space-xl;
}

.account-menu__title {
  line-height: 1.2;
}

.account-menu__subtitle {
  margin: $space-lg 0;
}

.account-menu__section {
  margin-bottom: $space-lg;
}

.account-menu__button {
  margin-right: $space-sm;
}
