@import '../mixins';

.inbox {
  background: $neutral-darker;
  height: 100%;
}

.inbox .confirmation {
  height: calc(100% - 3rem);
}
