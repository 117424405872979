@import '../mixins';

.persona {
  display: flex;
  width: 100%;
  margin-bottom: $space-lg;
  background: none;
  border: none;
  color: #fff;
  text-align: left;
  font-family: $font-nunito;
}

.persona__image {
  width: 78px;
  margin-right: $space-lg;
  border-radius: 100%;
}

.persona__details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.persona__label {
  display: flex;
  align-items: center;
  margin-bottom: $space-xs;
}

.persona__frequency {
  padding: 0 $space-xs;
  margin-right: $space-sm;
  border-radius: 1rem;
  background: $neutral-dark;
  font-size: $font-size-xs;

  &.frequency--24 {
    background: #c50000;
  }

  &.frequency--12 {
    background: #d46600;
  }

  &.frequency--6 {
    background: #d49900;
  }

  &.frequency--3 {
    background: #46ab74;
  }

  &.frequency--1 {
    background: #3884bc;
  }

  &.frequency--0 {
    background: #8a5cad;
  }
}

.persona__title {
  font-size: $font-size-md;
  font-weight: 800;
}

.persona__description {
  margin-bottom: $space-xs;
  color: $neutral-light;
  font-size: $font-size-sm;
  font-weight: 400;

  span:nth-child(even):not(:empty)::before,
  span:nth-child(even):not(:empty)::after {
    content: '/';
    padding: 0 $space-xs;
  }
  span:last-child:not(:empty)::after {
    content: '';
  }
}
