@import '../mixins';

.confirmation {
  display: flex;
  flex-direction: column;

  &.confirmation--full {
    text-align: center;
    justify-content: center;
    height: 100%;
  }
}

.confirmation-complete,
.confirmation-delete,
.confirmation-inbox {
  height: 100%;
  min-height: auto;
}

// Heading
// =======

.confirmation__heading {
  margin-bottom: $space-lg;

  .confirmation--full & {
    margin-bottom: $space-xl;
    text-shadow: $red-glow-text;
  }
}

// Buttons
// =======

.confirmation__button {
  align-self: flex-end;
  margin: $space-lg 0 0;

  .confirmation--wide-button & {
    width: 100%;
  }

  .confirmation--full & {
    align-self: center;
    margin: $space-xl 0 0;
  }
}

.confirmation__opt-out {
  margin-top: $space-xl;
  text-align: center;
  color: $neutral-light;
  font-size: $font-size-sm;
  line-height: 1.5;

  strong {
    color: $neutral-lightest;
  }
}
