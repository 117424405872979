@import '../mixins';

.prelude {
  height: auto;
}

.prelude__introduction {
  margin-bottom: $space-xl;
}

.prelude__title {
  margin-bottom: $space-lg;
}
