@import '../mixins';

.leaderboard-item {
  margin: 2rem 0;
  padding-left: 2rem;
  font-size: 3.5rem;
  font-family: $font-josefinsans;
  font-weight: 100;
  text-transform: uppercase;
}

.leaderboard-item__avatar {
  vertical-align: middle;
  overflow: hidden;
  display: inline-block;
  width: 5rem;
  height: 5rem;
  margin: 0 2rem 1rem 0;
  border-radius: 100%;
}

.leaderboard-item__avatar-image {
  width: 100%;
  height: 100%;
}

.leaderboard-item__name {
  margin-top: $space-sm;
}
