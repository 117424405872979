@import '../mixins';

.updates {
  background: $neutral-darker;
  height: 100%;
}

.updates__list {
  list-style: none;
}

.updates .confirmation {
  height: calc(100% - 3rem);
}
