@import '../mixins';

.alert-banner {
  padding: $space-sm;
  background: $neutral-darker;
  text-align: center;
  font-size: $font-size-xs;
  line-height: 1.2;

  &.alert-banner--warning {
    background: $red;
  }
}
