@import '../mixins';

.tag {
  background-color: $neutral-dark;
  border-radius: $corner-sm;
  padding: 2px $space-md;
  font-size: $font-size-sm;
  font-weight: 800;
}

.tag-with-icon {
  display: flex;
  align-items: center;
  align-self: flex-start;
  font-weight: 400;
}

.tag__icon {
  margin-right: $space-xs;
}
