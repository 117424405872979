@import '../mixins';

// Text Input
// ==========

.form__text-input {
  padding: $space-sm $space-md;
  background: $neutral-dark;
  border: 0.125rem solid $neutral-dark;
  border-radius: $corner-xs;
  outline: 0;
  color: $neutral-lightest;
  font-family: $font-nunito;
  font-size: $font-size-md;

  &::placeholder {
    color: $neutral-light;
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  &:active,
  &:hover {
    border: 0.125rem solid $neutral-midtone;
  }

  &:focus {
    border: 0.125rem solid $neutral-lightest;
  }

  &.form--error {
    border-color: $red;
  }

  &:-webkit-autofill {
    box-shadow: inset 3rem 3rem $neutral-dark !important;
    -webkit-text-fill-color: $neutral-lightest !important;
    background-clip: content-box !important;
  }

  &:disabled,
  &.form__text-input--disabled {
    background: $neutral-darker;
    color: $neutral-midtone;
    font-style: italic;

    &:hover,
    &:active,
    &:focus {
      border: transparent;
    }
  }

  &:-webkit-autofill:hover,
  &:-webkit-autofill:active,
  &:-webkit-autofill:focus {
    box-shadow: inset 3rem 3rem $neutral-dark !important;
    -webkit-text-fill-color: $neutral-lightest !important;
    background-clip: content-box !important;
  }
}
