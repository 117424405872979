@import '../mixins';

.header-overlay {
  z-index: $z-header;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  background-color: $neutral-darker;
  align-items: center;
  justify-content: space-between;
  padding: 0 $space-xs 0 $space-lg;
  height: 3rem;
}

.header-overlay__title-wrapper {
  flex: 1;
  min-width: 0;

  .header-overlay--with-icon & {
    margin: 0 $space-sm 0 $space-lg;
    text-align: center;
  }
}

.header-overlay__title {
  margin-top: $space-xs; // to counter weird font lineheight
  font-size: $font-size-lg;
  font-family: $font-josefinsans;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-sm;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-overlay__icon-close {
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  border: none;
}
