@import '../mixins';

@keyframes heart {
  to {
    stroke-dashoffset: 0;
  }
}

.loader {
  padding: $space-xl * 2 $space-lg;
  text-align: center;

  &.loader--sm {
    padding: $space-md;
  }

  &.loader--xs {
    padding: 0;
  }

  &.loader--inline {
    display: inline-block;
    vertical-align: middle;
  }

  &.loader--full-page {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
    min-height: auto;
    padding: 0;
  }
}

.loader__icon {
  width: 5rem;
  height: auto;

  .loader--sm & {
    width: 2rem;
  }

  .loader--xs & {
    width: 1.5rem;
  }

  .loader--full-page & {
    position: relative;
    top: -$space-xl * 2;
  }
}

.loader__heart {
  fill: none;
  stroke-width: 8;
  stroke-linecap: round;
  stroke-linejoin: round;

  .loader--sm & {
    stroke-width: 12;
  }

  .loader--xs & {
    stroke-width: 16;
  }
}

// If you change 2s animation duration in the future
// please update the machines that use that duration
// to control their transistion times.
.loader__heart-left {
  stroke: $red;
  stroke-dasharray: 250;
  stroke-dashoffset: 500;
  animation: heart 2s ease-in-out 0s reverse infinite;

  .loader--white & {
    stroke: $neutral-lightest;
  }

  .loader--sm & {
    animation-duration: 1.5s;
  }
}

.loader__heart-right {
  stroke: $neutral-lightest;
  stroke-dasharray: 500;
  stroke-dashoffset: 1000;
  animation: heart 2s ease-in-out 0s forwards infinite;

  .loader--sm & {
    animation-duration: 1.5s;
  }
}

.loader--no-animation {
  .loader__heart-left {
    animation: none;
  }
  .loader__heart-right {
    animation: none;
  }
}
