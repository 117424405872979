@import '../mixins';

// Rating
// ======

.profile {
  overflow: scroll;
}

.profile__rating,
.profile__selfie-buttons {
  z-index: $z-ui;
  position: relative;
  padding: $space-lg;
}

.profile__rating-buttons,
.profile__selfie-buttons {
  z-index: $z-ui;
  position: relative;
  display: flex;
  justify-content: stretch;
}

.profile__rating-buttons {
  margin-top: -2.25rem;
}

.profile__rating-button,
.profile__selfie-button {
  flex: 1 1 auto;

  &:first-child {
    margin-right: $space-xs;
  }

  &:last-child {
    margin-left: $space-xs;
  }
}

.profile__undo-button {
  flex: 0 0 auto;
  min-width: 3rem;
  padding-left: $space-xs !important;
  padding-right: $space-xs !important;

  .button__icon {
    margin-right: 0;
  }

  &:first-child {
    margin-right: $space-xs;
  }

  &:last-child {
    margin-left: $space-xs;
  }
}

.profile__rating-feedback {
  margin-top: $space-md;
  text-align: center;
}

// Profile Meta (name, age, etc.)
// ==============================

.profile__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $space-lg;
}

.profile__heading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
}

.profile__heading-status {
  margin: 0 0 $space-xs $space-sm;
}

.profile__headline {
  margin-top: $space-md;
  text-align: center;
}

// Stats (Age, Gender, Sexuality)
// ==============================

.profile__stats {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  margin-top: $space-md;
  list-style: none;
}

.profile__stats-item {
  margin: $space-xs;
}

// Actions (Fave, Block, Chat)
// ===========================

.profile__actions {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: $space-sm 0 0;
}

.profile__action {
  margin: 0 $space-xs;
}

.profile__actions-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: rgba($neutral-darkest, 0.9);
}

// Last Logged In
// ==============

.profile__last-login {
  padding: $space-lg;
  text-align: center;
  font-size: $font-size-xs;
  color: $neutral-light;
}

// Ban Actions
// ===========

.profile__ban {
  padding: $space-xl $space-lg 0;
}

.profile__ban-feedback {
  margin-bottom: $space-md;
}

.profile__ban-warning {
  margin-bottom: $space-sm;
  text-align: center;
  font-size: $font-size-xs;
}

.profile__ban-button {
  width: 100%;

  &.profile--ban-saving {
    background: $neutral-dark;
    color: $neutral-midtone;
  }

  &.profile--ban-complete {
    background: $neutral-dark;
    color: $neutral-lightest;
  }
}
