@import '../mixins';

.chat-bubble {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &.chat-bubble--reciever {
    align-self: flex-start;
  }

  &.chat-bubble--sender {
    align-self: flex-end;
  }
}

.chat-bubble__message {
  display: inline-block;
  max-width: 85%;
  padding: $space-sm $space-md;
  background: $neutral-dark;
  border-radius: 1.25rem;

  .chat-bubble--reciever & {
    border-bottom-left-radius: 0;
    align-self: flex-start;
  }

  .chat-bubble--sender & {
    border-bottom-right-radius: 0;
    background: rgba($red, 0.5);
    align-self: flex-end;
  }

  .chat-bubble--loader & {
    padding: $space-xs $space-md;
    max-width: 100%;
  }
}

.chat-bubble__receipt {
  align-self: flex-end;
  margin-top: $space-xs;
  font-size: $font-size-xs;
  text-align: right;
  color: $neutral-midtone;
}
