@import '../mixins';

$pink: #e18cff;

.showcase {
  padding: $space-xl;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.showcase__section {
  margin: $space-lg 0;

  &.showcase--mobile {
    max-width: 30rem;
  }

  &.showcase--display {
    width: 58rem;
  }
}

.showcase__title {
  margin-bottom: $space-xl;
  text-align: center;
  font-size: $font-size-xl;
  font-family: $font-josefinsans;
  color: $neutral-lighter;
}

.showcase__subtitle {
  margin-bottom: $space-md;
  margin-top: $space-xl;
  text-align: left;
  font-family: $font-josefinsans;
  color: $pink;
  text-transform: uppercase;
  font-size: $font-size-sm;
}

.showcase__grid {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: $space-md 0;

  > * {
    margin: 0 $space-sm;
  }

  &.showcase--stretch {
    > * {
      flex: 1 1 auto;
    }
  }
}

.showcase__background {
  padding: $space-md;
  background: $neutral-darker;
}

.showcase__label {
  font-size: $font-size-xs;
  color: $pink;
  font-family: $font-josefinsans;
  display: flex;
  align-items: center;
  margin-bottom: $space-xs;

  .showcase--stretch & {
    flex: 0 0 auto;
  }
}
