@import '../mixins';

.browse__items {
  list-style-type: none;
  display: grid;
  padding: 0;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0.25rem;
  row-gap: 0.25rem;
}
