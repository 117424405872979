@import '../mixins';

.chat {
  height: 100%;
}

.chat-wrapper {
  overflow: scroll;
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
}

.chat-panel {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;
  background: $neutral-darkest;
}

.chat-panel__convo {
  flex: 1 1 auto;
  padding-bottom: $space-xl;
  justify-content: flex-end;
}

.chat-panel__item {
  display: flex;
  flex-direction: column;
  // Spacing between text groups

  &.chat-panel--reciever + .chat-panel--sender,
  &.chat-panel--sender + .chat-panel--reciever {
    margin-top: $space-md;
  }
}

.chat-panel__input {
  position: relative;
  padding: 0 $space-sm $space-sm;
}

.chat-panel__feedback {
  position: absolute;
  top: -2.15 * $space-xl;
  left: $space-sm;
  right: $space-sm;
}
