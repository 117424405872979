@import '../mixins';

.edit-profile__section {
  margin-bottom: $space-xl * 2;
}

.edit-profile__description {
  margin-bottom: $space-xl;
  color: $neutral-light;
}

.edit-profile__heading {
  margin-bottom: $space-md;
}

.edit-profile__subheading {
  margin-bottom: $space-md;
}

.edit-profile__feedback {
  margin-bottom: $space-md;
}

.edit-profile__buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.edit-profile__button {
  width: 100%;

  & + & {
    margin-top: $space-md;
  }
}
