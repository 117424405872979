@import '../mixins';

.header-marketing {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $space-sm $space-md $space-sm $space-lg;
  height: 3.75rem;
  background: $neutral-darkest;
}

.header-marketing__nav {
  display: flex;
  flex: 0 0 auto;
}

.header-marketing__nav-item {
  min-width: 6rem;
  min-height: 2.25rem;
  margin-left: $space-sm;
  padding: $space-sm $space-sm $space-xs;
  font-size: 0.8rem;
}
