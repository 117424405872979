@import '../mixins';

.icon-button {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 2.75rem;
  min-height: 2.75rem;
  background: transparent;
  border: 0;
  border-radius: $corner-xs;
  outline: none;
  cursor: pointer;

  // States
  // ------

  &:active {
    .icon-button__icon path {
      fill: $red-neon;
    }
  }

  &:focus {
    background: rgba($red, 0.25);
  }

  &:disabled {
    cursor: default;
  }

  // Variants
  // --------

  &.icon-button--profile {
    min-width: 4.25rem;
    min-height: 4.25rem;
    padding: $space-xs;
  }
}

// Button Icon
// ===========

.icon-button__icon {
  // Button Icon in Variants
  // =======================

  // Nav Item Icon
  // -------------

  .icon-button--nav & {
    filter: none;
  }

  // Profile Action Icon
  // --------------------

  .icon-button--profile & {
    filter: $red-glow-icon-lg;
  }

  // Disabled State
  // --------------

  .icon-button:disabled & {
    filter: none;

    path {
      fill: $neutral-midtone;
    }
  }
}

// Button Label
// ============

.icon-button__label {
  font-family: $font-nunito;
  font-size: $font-size-xxs;
  color: $neutral-midtone;
  line-height: 1;
  text-transform: lowercase;

  // Profile Action Label
  // --------------------

  .icon-button--profile & {
    font-size: $font-size-md;
    font-weight: 600;
    color: $neutral-lightest;
    text-shadow: $red-glow-text;
  }

  // Labelless Button hidden label
  // -----------------------------
  // Hidden but visible for screen readers

  .icon-button--labelless & {
    opacity: 0;
    height: 0;
    width: 0;
  }

  // Disabled State
  // --------------

  .icon-button:disabled & {
    color: $neutral-midtone;
    text-shadow: none;
  }
}
