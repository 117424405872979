@import '../mixins';

.exhibition {
  height: 100%;
}

.chat-wrapper {
  overflow: scroll;
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
}

.chat-panel {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;
  background: $neutral-darkest;
}

.hot-or-not-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hot-or-not-panel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  background: $neutral-darkest;
}

.exhibition-body {
  flex: 1 1 auto;
  padding-bottom: $space-xl;
  justify-content: center;
}
