@import '../mixins';

@keyframes hideshow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.125;
  }
}

.chat-loader {
  display: block;
  height: 2rem;
}

.loader-dot {
  fill: $neutral-light;
}

.loader-dot--1 {
  animation: hideshow 1s 0s ease infinite;
}

.loader-dot--2 {
  animation: hideshow 1s 0.25s ease infinite;
}

.loader-dot--3 {
  animation: hideshow 1s 0.5s ease infinite;
}
