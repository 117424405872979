@import '../mixins';

.status-tag {
  display: flex;
  align-items: center;
  padding: 0.125rem $space-sm 0.125rem $space-xs;
  background: $neutral-lightest;
  border-radius: $corner-md;
  font-size: $font-size-xs;
  line-height: 1;
  color: $neutral-darker;

  &.status-tag--user-online {
    font-weight: 800;
  }
}

.status-tag__icon {
  margin-right: $space-xs;
  filter: none;

  .status-tag--online &,
  .status-tag--user-online & {
    filter: drop-shadow(0 0 0.05rem rgba($red-neon, 1))
      drop-shadow(0 0 0.05rem rgba($red-neon, 1))
      drop-shadow(0 0 0.15rem rgba($red-neon, 1));

    circle {
      fill: $red-neon;
    }
  }

  .status-tag--inactive & {
    filter: drop-shadow(0 0 0.05rem rgba($red-neon, 1))
      drop-shadow(0 0 0.05rem rgba($red-neon, 1))
      drop-shadow(0 0 0.05rem rgba($red-neon, 1))
      drop-shadow(0 0 0.15rem rgba($red-neon, 1));

    circle {
      fill: $neutral-lightest;
      stroke: $red-neon;
    }
  }

  .status-tag--offline & {
    filter: none;
    circle {
      fill: $neutral-midtone;
      stroke: $neutral-midtone;
      stroke-width: 4;
      filter: none;
    }
  }
}

.status-tag__label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
