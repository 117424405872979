@import '../mixins';

.text-link {
  padding: 0 $space-xs/2; // to give the glow a wee bit more space
  color: $neutral-lightest;
  font-weight: 800;
  text-decoration: none;
  text-shadow: $red-glow-text;

  &:hover {
    text-shadow: $red-glow-text-lg;
  }

  &:active {
    color: $red-neon;
  }

  &:focus {
    background: rgba($red-neon, 0.5);
    border-radius: $corner-xs;
    outline: 0;
    color: $neutral-lightest;
  }
}
