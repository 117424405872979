@import '../mixins';

.chat-input {
  display: flex;
  width: 100%;
  background: $neutral-dark;
  border-radius: $corner-xs;
  justify-content: stretch;
  align-items: center;
  border: 0;
}

.chat-input .icon-chat {
  position: relative;
  left: $space-sm;
}

.chat-input__input {
  width: 100%;
  margin: 0 $space-xs;
  padding: $space-sm $space-xs;
  border-radius: 0;
  margin-left: $space-md;

  &:active,
  &:focus {
    border-color: $neutral-dark;
  }

  &:disabled {
    border: none;
  }
}

.chat-input__button {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: $space-sm;
    bottom: $space-sm;
    width: 0.1rem;
    background: rgba($neutral-lightest, 0.25);
  }
}

.chat-input__blocked .chat-input {
  background: $neutral-darker;
}
