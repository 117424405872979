@import '../mixins';

.feedback {
  padding: $space-sm $space-md;
  background: rgba($red-dark, 0.8);
  border-radius: $corner-xs;

  &.feedback--strong {
    text-align: center;
    font-weight: 800;
  }
}

.feedback__icon {
  margin: -0.2rem $space-xs 0 0;
  vertical-align: middle;
}
