@import '../mixins';

.signup {
  display: flex;
  flex-direction: column;
  padding: $space-xl * 2 $space-lg;
  min-height: auto;
  height: 100%;
}

.signup__subheading {
  margin-top: $space-md;
}

.signup__description {
  margin-top: $space-md;
  color: $neutral-light;
}

.signup__form {
  display: flex;
  flex-direction: column;
  margin: $space-xl 0;
}

.signup__button {
  margin: $space-md 0;
  align-self: flex-end;
}

.signup__feedback {
  margin: $space-md 0;
}

.signup-confirmation {
  height: 100%;
}

.email-sent {
  min-height: auto;
}

// Terms of Service

.tos__highlight {
  margin-bottom: $space-lg;
}

.tos__subheading {
  margin: $space-md 0;
}

.tos__button {
  margin-top: $space-xl;
  margin-bottom: $space-xl;
}
