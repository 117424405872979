@import '../mixins';

.form__container {
  display: flex;
  flex-direction: column;

  > *:not(:first-child) {
    margin-top: $space-lg;
  }
}

.form__fieldset {
  display: flex;
  flex-direction: column;
  outline: 0;
  border: 0;
}

.form__label-wrapper {
  margin-bottom: $space-xs;

  .form--hint & {
    display: flex;
    justify-content: space-between;
  }
}

.form__label {
  font-size: $font-size-xs;
  font-weight: 800;
  letter-spacing: $letter-spacing-md;
  text-transform: uppercase;
}

.form__hint {
  font-size: $font-size-xs;
  color: $neutral-light;
  text-transform: lowercase;
  letter-spacing: $letter-spacing-sm;
  font-style: italic;
}

.form__button {
  align-self: flex-end;
}
