@import '../mixins';

.app-frame-container {
  height: 100%;

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    width: 100%;
    max-width: 100vw;
  }
}

.app-frame {
  height: 100%;

  @media (min-width: $screen-xs-min) {
    width: 24rem;
    height: 48rem;
    background-color: white;
    border: 1.25rem solid white;
    border-radius: 2rem;
    position: relative;
    top: calc(50% - 24rem);
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    border: none;
    width: 100%;
    height: 100%;
    top: auto;
  }
}

.app-body {
  z-index: $z-header;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  overflow: scroll;
  position: relative;
  background-color: $neutral-darkest;

  @media (min-width: $screen-xs-min) {
    justify-content: flex-start;
    border-radius: 1rem;
  }
}

.confirm-email__description {
  color: $neutral-light;
}

.confirm-email__form {
  padding: $space-lg 0;
}
