@import '../mixins';

// Form Item
// =========
// Used for input groups (ex. radio, checkbox)

.form__item {
  display: flex;
  margin-top: $space-md;
}

.form__item-label {
  // vertical-align: middle;
  margin-top: $space-xs;
  color: $neutral-light;

  &.form--disabled {
    color: $neutral-midtone;
    font-style: italic;
  }

  &.form--checked {
    font-weight: 800;
  }
}

// Radio & Checkbox Input
// ======================

.form__option {
  flex: 0 0 auto;
  position: relative;
  width: 1.75rem;
  height: 1.75rem;
  margin-right: $space-md;
  appearance: none;
  outline: 0;
  border-radius: $corner-xs;
  border: 0.125rem solid $neutral-midtone;
  background: $neutral-darkest;
  vertical-align: middle;

  &:checked {
    border-color: $neutral-dark;
    background: $neutral-dark;
  }

  &:disabled {
    background: $neutral-darker;
    border-color: $neutral-dark;
  }

  &:focus {
    border: 0.125rem solid $neutral-lightest;
  }

  // Radio Styles
  // ------------

  &.form--radio {
    border-radius: 50%;

    &:checked {
      background: $neutral-lightest;
      box-shadow: inset 0 0 0 0.4rem $neutral-dark;
    }
  }

  // Checkbox Styles
  // ---------------

  &.form--checkbox {
    &:checked::after {
      content: url(../../images/icon/check.svg);
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
