@import '../mixins';

.gallery-wrapper {
  overflow: scroll;
}

.gallery {
  overflow: hidden;
  position: relative;
  min-height: 100vw;
  background: $neutral-darker;

  @media (min-width: $screen-xs-min) {
    min-height: 320px;
  }
}

// Gallery Slide Count
// ===================

.gallery__count {
  z-index: $z-ui;
  position: absolute;
  top: $space-md;
  left: $space-md;
  padding: $space-xs $space-md;
  margin: 0;
  background: rgba($neutral-darkest, 0.8);
  border-radius: $corner-md;
  font-size: $font-size-xs;
  list-style: none;

  li {
    display: none;

    &.slick-active {
      display: inline;
    }
  }
}

.gallery__count-number {
  font-weight: bold;
}

// Gallery Image
// =============

.gallery .slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  position: relative;
  display: block !important;

  img {
    display: block !important;
  }
}

// Gallery Navigation
// ==================

.gallery__nav {
  z-index: $z-ui;
  position: absolute;
  top: 50%;
  margin-top: -2.5rem;
  width: 5rem;
  height: 5rem;
  padding: 0.75rem;
  background: rgba($neutral-darkest, 0.5) !important; // prevent overrides
  border-radius: 0;

  &:active,
  &:focus {
    background: rgba($red, 0.6);
  }

  &.gallery--prev {
    left: -2.5rem;
    align-items: flex-end;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  &.gallery--next {
    right: -2.5rem;
    align-items: flex-start;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }
}
