@import '../mixins';

.chat-convo {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: $space-sm $space-lg;

  > *:not(:first-child) {
    margin-top: $space-xs;
  }
}
