@import '../mixins';

.marketing-screen {
  padding: $space-lg;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 6rem);
  position: relative;
  overflow: hidden;

  @media (min-width: $screen-xs-min) {
    height: 100%;
    min-height: auto;
  }
}

.marketing-screen__logo {
  margin: $space-xl 0 $space-lg;
  width: 90%;

  .logo {
    width: 100%;
  }
}

.marketing-screen__button {
  margin: $space-lg;
  padding-left: $space-md;
  padding-right: $space-md;
}

.marketing-screen__image {
  margin-top: $space-xl;
  max-width: 75%;
}

.marketing-screen__description {
  text-align: center;
}

.marketing-screen__link-description {
  font-size: $font-size-xs;
}

.marketing-screen__link {
  text-transform: uppercase;
  font-size: $font-size-xs;
  text-shadow: $red-glow-text;
  margin-top: $space-xs;

  &:hover {
    text-shadow: $red-glow-text-lg;
  }

  &:active {
    color: $red-neon;
  }

  &:focus {
    background: rgba($red-neon, 0.5);
    border-radius: $corner-xs;
    outline: 0;
    color: $neutral-lightest;
  }
}
